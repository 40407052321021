"use client";
import { useState } from "react";
import { UserData } from "@/utils/userData";
import { Sidebar } from "@/partials/Sidebar";
import { Header } from "@/partials/Header";
import { Banner } from "@/partials/Banner";

const Datepicker = (...args: any[]) => <></>;
const FilterButton = (...args: any[]) => <></>;

export const Template = ({ children, user }: { children: any; user: UserData }) => {
    const [sidebarOpen, setSidebarOpen] = useState(true);

    return (
        <div className="min-h-screen flex flex-col min-w-fit">
            {/* Header section */}
            <Header user={user} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}></Header>
            {/* <header className="bg-gray-200 p-4  flex justify-between flex w-full">
                <div>Left side of header</div>
                <div className="flex justify-end">Right side of header</div>
            </header> */}

            {/* Main section */}
            <main className="flex flex-1">
                {/* Sidebar */}
                <aside className="bg-gray-100 dark:bg-gray-900 w-1/4 p-4 text-nowrap overflow-hidden">
                    <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                </aside>

                {/* Main panel */}
                <div className="flex-1 p-4">{children}</div>
            </main>
        </div>
    );
};
export default Template;
