import { useState } from "react";
import { UserData } from "@/utils/userData";
import ThemeToggle from "@/components/ThemeToggle";
import UserMenu from "@/components/DropDownProfile";
import Help from "@/components/DropDownHelp";
import Notifications from "@/components/DropDownNotifications";

const SearchModal = (...args: any[]) => {
    return <></>;
};

export const Header =  ({
    user,
    sidebarOpen,
    setSidebarOpen,
    variant = "default",
}: {
    user: UserData;
    sidebarOpen: boolean;
    setSidebarOpen: (a: boolean) => void;
    variant?: string;
}) => {
    const [searchModalOpen, setSearchModalOpen] = useState(true);

    return (
        <header className="flex justify-between w-full p-2">
            <div>
                {/* Hamburger button */}
                <button
                    className="text-gray-500 hover:text-gray-600 dark:hover:text-gray-400 lg:hidden"
                    aria-controls="sidebar"
                    aria-expanded={sidebarOpen}
                    onClick={(e) => {
                        e.stopPropagation();
                        setSidebarOpen(!sidebarOpen);
                    }}
                >
                    <span className="sr-only">Open sidebar</span>
                    <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <rect x="4" y="5" width="16" height="2" />
                        <rect x="4" y="11" width="16" height="2" />
                        <rect x="4" y="17" width="16" height="2" />
                    </svg>
                </button>
            </div>
            <div className="flex justify-end">
                <div>
                    <button
                        className={`w-8 h-8 flex items-center justify-center hover:bg-gray-100 lg:hover:bg-gray-200 dark:hover:bg-gray-700/50 dark:lg:hover:bg-gray-800 rounded-full ml-3 ${searchModalOpen && "bg-gray-200 dark:bg-gray-800"}`}
                        onClick={(e) => {
                            e.stopPropagation();
                            setSearchModalOpen(true);
                        }}
                        aria-controls="search-modal"
                    >
                        <span className="sr-only">Search</span>
                        <svg
                            className="fill-current text-gray-500/80 dark:text-gray-400/80"
                            width={16}
                            height={16}
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7ZM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5Z" />
                            <path d="m13.314 11.9 2.393 2.393a.999.999 0 1 1-1.414 1.414L11.9 13.314a8.019 8.019 0 0 0 1.414-1.414Z" />
                        </svg>
                    </button>

                    <SearchModal
                        id="search-modal"
                        searchId="search"
                        modalOpen={searchModalOpen}
                        setModalOpen={setSearchModalOpen}
                    />
                </div>
                <Notifications align="right" />
                <Help align="right" />
                <ThemeToggle />
                {/*  Divider */}
                <hr className="w-px h-6 bg-gray-200 dark:bg-gray-700/60 border-none" />
                <UserMenu username={user?.given_name || ""}  picture={user?.picture || ""} role="user" align="right" />
            </div>
        </header>
    );
};

export default Header;
