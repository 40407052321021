"use client";
import { createContext, useContext, useState, useEffect } from "react";
declare const localStorage: Storage;

const ThemeContext = createContext<{
    currentTheme: "light" | "dark";
    changeCurrentTheme: (currentTheme: "light" | "dark") => void;
}>({
    currentTheme: "light",
    changeCurrentTheme: () => {},
});

function getPersistedTheme(): "light" | "dark" | null {
    return "light"; // localStorage.getItem("theme") as "light" | "dark";
}
export default function ThemeProvider({ children }: { children: any }) {

    const persistedTheme: "light" | "dark" = getPersistedTheme() || "dark";
  
    const [theme, setTheme] = useState<"light"|"dark">(persistedTheme || "light");

    const changeCurrentTheme = (newTheme: "light" | "dark") => {
        setTheme(newTheme);
        localStorage.setItem("theme", newTheme);
    };

    useEffect(() => {
        document.documentElement.classList.add("[&_*]:!transition-none");
        if (theme === "light") {
            document.documentElement.classList.remove("dark");
            document.documentElement.style.colorScheme = "light";
        } else {
            document.documentElement.classList.add("dark");
            document.documentElement.style.colorScheme = "dark";
        }

        const transitionTimeout = setTimeout(() => {
            document.documentElement.classList.remove("[&_*]:!transition-none");
        }, 1);

        return () => clearTimeout(transitionTimeout);
    }, [theme]);

    return (
        <ThemeContext.Provider value={{ currentTheme: theme, changeCurrentTheme }}>
            {children}
        </ThemeContext.Provider>
    );
}

export const useThemeProvider = () => useContext(ThemeContext);
