"use client";
import Link from "next/link";
export const Sidebar = ({
    sidebarOpen,
    setSidebarOpen,
    variant = "default",
}: {
    sidebarOpen: boolean;
    setSidebarOpen: (a: boolean) => void;
    variant?: string;
}) => {
    return (
        <div className="min-w-fit">
            <ul className="space-y-4">
                <li className="font-bold">
                    📖 Getting Started
                    <ul className="ml-4 space-y-2 font-medium m-3">
                        <li className="m-2">
                            <Link href="/dashboard/documentation">📚 Documentation</Link>
                        </li>
                        <li className="m-2">
                            <Link href="/">📺 Video Tutorials</Link>
                        </li>
                        <li className="m-2">
                            <Link href="/">👨‍💻 API Reference</Link>
                        </li>
                    </ul>
                </li>
                <li>
                    🔧 Operations
                    <ul className="ml-4 space-y-2 font-medium m-3">
                        <li className="m-2">
                            <Link href="/dashboard/show-token">🔑 your token </Link>
                        </li>
                        <li className="m-2">
                            <Link href="/dashboard/agents">📎 your agents & servers </Link>
                        </li>
                        <li className="m-2">
                            <Link href="/dashboard/monitoring">📈 Monitoring </Link>
                        </li>
                        <li className="m-2">
                            <Link href="/dashboard/notifications">🔔 Notifications </Link>
                        </li>
                        <li className="m-2">
                            <Link href="/dashboard/get-agents">📥 download agent </Link>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    );
};

export default Sidebar;
